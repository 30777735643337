/* General Styles */
html, body {
    background-image: url('./images/BLACKHEX.jpg'); /* Ensure this path is correct */
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* Fix the background in place */
    color: #00ff00;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Ensure body takes full height of the viewport */
    overflow-x: hidden; /* Prevent horizontal scrolling */
    touch-action: none;  /* Prevent touch-based swiping */
}

/* Prevent scrolling on the body */
body {
    position: fixed;
    width: 100%;
}


/* Ensure that the main content fills up the available space */
.App {
    flex: 1;
    display: flex;
    flex-direction: column;
}

/* App Title Styles */
.app-title {
    text-align: center;
    margin: 20px 0;
    color: #0a0a0a;
    text-shadow: 0px 0px 30px #02ff02;
    font-size: 2rem;
    position: relative; /* Required for positioning the pseudo-element */
}

.app-title h1 {
    font-size: 3.5rem;
    margin: 0;
    padding: 0;
}

/* Glowing Green Line */
.app-title::after {
    content: '';
    display: block;
    width: 700px; /* Default width for larger screens */
    height: 2.5px; /* Height of the line */
    background-color: #02ff02; /* Green color for the line */
    border-radius: 10px; /* Rounded edges */
    margin: 0px auto 0; /* Center the line below the title */
    box-shadow: 0 0 25px #02ff02; /* Green glowing effect */
}

/* Adjust the width of the line for tablets */
@media (max-width: 768px) {
    .app-title::after {
        width: 500px; /* Adjusted width for tablets */
    }
}

/* Adjust the width of the line for mobile devices */
@media (max-width: 480px) {
    .app-title::after {
        width: 300px; /* Adjusted width for mobile devices */
    }
}

/* Header Styles */
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.0);
}

.header-left img {
    height: 60px;
}

.header-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
}

.header-right .nav-link {
    margin-left: 10px;
    color: #00ff00;
    text-decoration: none;
    font-size: 0.8rem;
    padding: 8px 10px;
    border: 2px solid transparent;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 10px 0px #00ff00;
    border-radius: 5px;
    background-color: black;
}

.header-right .nav-link:hover {
    text-decoration: underline;
    border: 2px solid #00ff00;
}

/* Container for the gallery */
.gallery-container {
    width: 100%; /* Ensure the container takes full width */
    max-width: 100%; /* Ensure the container does not exceed the width of the screen */
    margin: 0 auto; /* Center the container */
    padding: 10px; /* Optional padding */
    box-sizing: border-box; /* Include padding in the width */
}

/* Custom Gallery Styles */
.custom-gallery {
    display: flex;
    overflow: hidden;
    width: 100%; /* Full width of the parent container */
    max-width: 500px; /* Limit the width to 500px on larger screens */
    height: auto; /* Adjust height automatically based on content */
    margin: 0 auto;
    border: 2px solid #00ff00;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px #00ff00;
}

/* Wrap images inside the gallery */
.gallery-images-wrapper {
    display: flex;
    width: 100%; /* Ensure wrapper fits within the container */
    animation: scroll-left 15s linear infinite;
}

/* Style each image */
.gallery-image {
    flex: 0 0 auto;
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the whole image is visible */
}

/* Add media query for mobile view */
@media (max-width: 600px) {
    .gallery-container {
        width: 65%; /* Ensure the container takes full width on mobile */
        padding: 0; /* Remove padding if it causes issues */
    }

    .custom-gallery {
        max-width: 100%; /* Full width on mobile screens */
        height: auto; /* Let height adjust automatically */
    }

    .gallery-image {
        height: auto; /* Maintain image aspect ratio */
        width: 100%; /* Ensure the image takes up the full width of its container */
    }
}

/* Animation for continuous scrolling */
@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Action Buttons */
.action-buttons {
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
}

.custom-btn {
    background-color: black;
    color: #00ff00;
    border: 2px solid #00ff00;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 15px 0px #00ff00;
    font-size: 1rem;
    border-radius: 5px;
}

.custom-btn:hover {
    background-color: #00ff00;
    color: black;
}

/* Data Entry Boxes */
input[type="text"], input[type="number"], input[type="password"], textarea {
    background-color: black;
    color: #00ff00;
    border: 2px solid #00ff00;
    padding: 10px;
    margin: 10px 0;
    width: 80%;
    max-width: 400px;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #00ff00;
}

input::placeholder, textarea::placeholder {
    color: #00ff00;
}

input:focus, textarea:focus {
    outline: none;
    box-shadow: 0px 0px 15px 0px #00ff00;
}

.header-left {
    display: flex;
    align-items: center;
}

.wallet-connect {
    font-size: 0.8rem;
    margin-left: 20px; /* Add some space between the logo and wallet button */
    position: relative; /* Remove fixed positioning */
}


/* Footer Styles */
footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    padding-bottom: 20px;
    width: 100%; /* Ensure the footer takes full width */
    position: sticky;
    bottom: 0;
}

.footer-left {
    flex: 1; /* Push the left side to the far left */
    text-align: left;
    font-size: 16px; /* Adjust the font size for the copyright text */
    color: #00ff00; /* Green text */
}

.footer-center {
    flex: -1; /* Center the icons in the middle */
    text-align: center;
}

.social-link {
    margin: 40px; /* Space between the social icons */
}

.social-icon {
    width: 30px;  /* Adjust the size of the icons */
    height: auto;
    transition: transform 0.3s;
}

.social-icon:hover {
    transform: scale(1.5); /* Slight zoom on hover */
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
    footer {
        padding: 15px; /* Reduce padding for smaller screens */
    }

    .footer-left {
        font-size: 14px; /* Reduce font size for copyright text */
    }

    .social-link {
        margin: 20px; /* Reduce space between social icons */
    }

    .social-icon {
        width: 25px;  /* Reduce the size of the social icons */
    }
}

@media (max-width: 480px) {
    footer {
        flex-direction: column; /* Stack elements vertically on smaller screens */
        text-align: center; /* Center all content */
        padding: 10px; /* Reduce padding further for mobile */
    }

    .footer-left {
        font-size: 12px; /* Further reduce the font size */
        text-align: center; /* Center text */
        margin-bottom: 10px; /* Add some space below */
    }

    .social-link {
        margin: 10px; /* Reduce the margin between social icons even more */
    }

    .social-icon {
        width: 20px; /* Make the icons smaller for mobile view */
    }
}


/* Responsive Adjustments */
@media (max-width: 1024px) {
    .app-title h1 {
        font-size: 1.75rem;
    }

    .header-right .nav-link {
        font-size: 16px;
        margin-left: 8px;
    }

    .custom-gallery {
        width: 70%;
        max-width: 400px;
        height: 250px;
    }

    .gallery-title p {
        font-size: 22px;
    }

    .custom-btn {
        padding: 8px 16px;
        font-size: 0.875rem;
    }

    input[type="text"], input[type="number"], input[type="password"], textarea {
        width: 90%;
        max-width: 300px;
    }

    footer {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    header {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-right {
        margin-top: 10px;
        width: 100%;
        justify-content: center;
    }

    .header-right .nav-link {
        font-size: 14px;
        margin: 5px 0;
        padding: 8px 10px;
        width: 100%;
        text-align: center;
    }

    .custom-gallery {
        width: 80%;
        max-width: 350px;
        height: 200px;
    }

    .gallery-title p {
        font-size: 20px;
    }

    .custom-btn {
        padding: 6px 12px;
        font-size: 0.8125rem;
    }

    input[type="text"], input[type="number"], input[type="password"], textarea {
        width: 80%;
        max-width: 300px;
    }

    .wallet-address {
        font-size: 0.75rem;
        max-width: 200px;
    }

    footer {
        flex-direction: column;
        padding: 20px;
    }

    .social-link {
        font-size: 16px;
        margin: 10px 0;
    }
}

@media (max-width: 480px) {
    .app-title h1 {
        font-size: 1.5rem;
    }

    .header-left img {
        height: 50px;
    }

    .header-right .nav-link {
        font-size: 12px;
        margin: 5px 0;
        padding: 6px 8px;
        width: 100%;
        text-align: center;
    }

    .custom-gallery {
        width: 90%;
        max-width: 300px;
        height: 150px;
    }

    .gallery-title p {
        font-size: 18px;
    }

    .custom-btn {
        padding: 5px 10px;
        font-size: 0.6rem;
    }

    input[type="text"], input[type="number"], input[type="password"], textarea {
        width: 60%;
        max-width: 100%;
        font-size: 0.6rem; 
        padding: 6px; /* Adjust padding to reduce height */
        line-height: 1.2; /* Adjust line-height to control text spacing */
        border-radius: 5px; /* Optional: rounded corners */
    }

    .wallet-address {
        font-size: 0.625rem;
        max-width: 150px;
    }

    footer {
        padding: 10px;
    }

    .social-link {
        font-size: 14px;
    }
}

/* Modal Styles */
.nft-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Darker background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .nft-modal-content {
    background: #000; /* Black background */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
  }
  
  .nft-modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .nft-modal-buttons button {
    padding: 10px 20px;
    background-color: #000; /* Black background */
    color: #00ff00;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
    transition: all 0.3s ease;
  }
  
  .nft-modal-buttons button:hover {
    box-shadow: 0px 0px 20px rgba(0, 255, 0, 1); /* Brighter green glow on hover */
  }
  
  .nft-modal-buttons button:disabled {
    background-color: #333; /* Dark gray background for disabled state */
    cursor: not-allowed;
    box-shadow: none;
  }
  
  /* Notification Styles */
.notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #000; /* Black background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
    text-align: center;
    z-index: 1000;
  }
  
  .notification-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .notification-content h2,
  .notification-content p {
    color: #00ff00; /* Green text */
    margin: 10px 0;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #00ff00; /* Green spinner */
    border-radius: 50%;
    width: 36px;
    height: 36px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Close Button Styles */
  .notification .close-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #000; /* Black background */
    color: #00ff00; /* Green text */
    border: 2px solid #00ff00; /* Green border */
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
  }
  
  .notification .close-button:hover {
    background-color: #00ff00; /* Green background on hover */
    color: #000; /* Black text on hover */
  }

  .fire-image {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 1900px; /* Adjust the size as needed */
    height: auto;
    z-index: -1; /* Lower z-index to place it behind other elements */
    opacity: 0.6; /* Optional: Make it slightly transparent */
  }
  
  .flipped-fire-image {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%) scaleY(-1); /* Flip vertically */
    width: 1900px;
    height: auto;
    z-index: -1;
    opacity: 0.6;
  }

/* Centering the gallery title and making the text bold */
.gallery-title {
    text-align: center;
    margin-bottom: 20px; /* Adjust the margin as needed */
}

.gallery-title p {
    font-size: 2rem; /* Default font size */
    color: #050505; /* Green color for text */
    text-shadow: 0px 0px 20px #00ff00; /* Green glowing effect */
    margin: 0; /* Remove any default margin */
    padding: 0; /* Remove any default padding */
    font-weight: bold; /* Make the text bold */
}

/* Adjust the font size for tablets */
@media (max-width: 768px) {
    .gallery-title p {
        font-size: 1.5rem; /* Slightly smaller font size for tablets */
    }
}

/* Adjust the font size for mobile devices */
@media (max-width: 480px) {
    .gallery-title p {
        font-size: 1.25rem; /* Smaller font size for mobile */
    }
}

/* TotalMints Component Styles */
.total-mints {
    position: fixed;
    top: 150px;
    right: 20px;
    background-color: #000; /* Black background */
    color: #00ff00; /* Green text */
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
    text-align: center; /* Center-align text */
    z-index: 1000;
  }
  
  .total-mints h2 {
    margin: 0 0 10px 0; /* Center the title with some margin below */
    font-size: 1.5rem;
  }
  
  .total-mints p {
    margin: 5px 0;
  }
  
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    color: #00ff00; /* Green color */
    font-size: 1rem;
    cursor: pointer;
  }

/* Custom Select Styles */
.custom-select {
    background-color: black;
    color: #00ff00;
    border: 2px solid #00ff00;
    padding: 10px;
    width: 50%;  /* Default width */
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #00ff00;
}

/* Option Styles */
.custom-select option {
    background-color: black;
    color: #00ff00;
}

/* Media Query for tablets (max-width: 768px) */
@media (max-width: 768px) {
    .custom-select {
        width: 70%;  /* Increase width for tablets */
        padding: 8px;  /* Adjust padding */
        font-size: 0.6rem;  /* Adjust font size */
    }
}

/* Media Query for mobile devices (max-width: 480px) */
@media (max-width: 480px) {
    .custom-select {
        width: 90%;  /* Increase width for mobile */
        padding: 6px;  /* Adjust padding */
        font-size: 0.6rem;  /* Adjust font size */
    }
}


/* Fireworks Effect */
.firework-effect {
    position: fixed;
    top: 23%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002; /* Make sure it's above other content */
    pointer-events: none; /* Prevents interaction with the fireworks */
  }
  
  .firework-effect img {
    max-width: 40%; /* Scale the fireworks down if needed */
    max-height: 40%;
  }
  
/* src/styles.css */

.nft-gallery-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }
  
  .nft-gallery-modal-content {
    background: #000;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    text-align: center;
    box-shadow: 0px 0px 15px rgba(0, 255, 0, 0.8);
  }
  
  .nft-gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .nft-item img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    border: 2px solid #00ff00;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    color: #00ff00;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .upgrade-fees {
    position: fixed;
    top: 150px;
    left: 20px; /* Position it on the left side */
    background-color: #000; /* Black background */
    color: #00ff00; /* Green text */
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
    text-align: center; /* Center-align text */
    z-index: 1000;
  }
  
  .upgrade-fees h2 {
    margin: 0 0 10px 0; /* Center the title with some margin below */
    font-size: 1.5rem;
  }
  
  .upgrade-fees p {
    margin: 5px 0;
  }
  
  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: transparent;
    border: none;
    color: #00ff00; /* Green color */
    font-size: 1rem;
    cursor: pointer;
  }

/* NFT Breakdown Modal Styles */
.nft-breakdown {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent black background */
    color: #00ff00; /* Green text */
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgba(0, 255, 0, 0.8); /* Green glowing shadow */
    text-align: center;
    z-index: 2000; /* Ensure it's on top of everything else */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nft-breakdown img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensure the image fits within the viewport */
  }
  
  .nft-breakdown .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #00ff00; /* Green color */
    font-size: 2rem;
    cursor: pointer;
    z-index: 2001; /* Ensure the close button is on top of the image */
  }

/* General layout for action sections */
.action-sections {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between sections */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .action-sections {
      flex-direction: column; /* Stack vertically on smaller screens */
      align-items: center;
      width: 100%;
    }
  }

  .centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    color: #00ff00; /* Green text color */
}

.centered-container h2 {
    font-size: 2rem; /* Increase size of the title only in the centered container */
}

.centered-container p {
    font-size: 1.5rem; /* Increase size of the countdown only in the centered container */
}

.admin-unlock-btn {
    position: absolute; /* Position the button absolutely */
    top: 20px; /* Distance from the top of the viewport */
    left: 20px; /* Distance from the left of the viewport */
    background-color: black;
    color: #00ff00;
    border: 2px solid #00ff00;
    padding: 10px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .admin-unlock-btn:hover {
    background-color: #00ff00;
    color: black;
  }
  
  
    
  
  



  














